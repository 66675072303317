<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h2 class="card-tile mb-2">Nouveau code promo</h2>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <h5 class="form-label">Code promo</h5>
                <input
                  v-model="codeTitre"
                  type="text"
                  class="form-control"
                  placeholder="Code promo"
                />
                <b-button
                  type="submit"
                  class="btn mt-2"
                  variant="outline-primary"
                  @click="generateCode"
                >
                  Générer un code
                </b-button>
              </div>
              <div class="mb-2">
                <h3>Général</h3>
              </div>
              <div class="mb-3">
                <h5 class="form-label">Type</h5>
                <v-select
                  style="cursor: pointer"
                  :options="typeCodePromo"
                  v-model="codeType"
                  placeholder="Type code promo"
                  label="text"
                  input-id="value"
                  value="value"
                />
              </div>
              <div v-if="codeType">
                <div class="mb-3" v-if="codeType.text == 'Remise panier fixe'">
                  <h5 class="form-label" for="ecommerce-product-price">
                    Valeur code promo
                  </h5>
                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="text"
                      name="price"
                      v-model="codePrice"
                      @keypress="stripTheGarbage($event)"
                      @blur="formatDinar()"
                      required
                    />
                    <span class="currency-symbol">TND </span>
                  </div>
                </div>
                <div
                  class="mb-3"
                  v-if="codeType.text == 'Remise en pourcentage'"
                >
                  <h5 class="form-label" for="ecommerce-product-price">
                    Valeur code promo
                  </h5>

                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="number"
                      name="price"
                      v-model="codePrice"
                      min="0"
                      max="100"
                      required
                    />
                    <span class="currency-symbol">% </span>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <h5>Date d’expiration du code</h5>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="codeExpiration"
                  locale="fr"
                ></b-form-datepicker>
              </div>
              <div class="mb-3">
                <h5>Autoriser l’expédition gratuite</h5>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                >
                  Cocher cette case si le code promo inclut l’expédition
                  gratuite.
                </b-form-checkbox>
              </div>
              <div class="mb-2">
                <h3>Réstriction d'usage</h3>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <h5 class="form-label">
                      Dépense minimale
                      <feather-icon
                        style="color: #19b4bf; cursor: pointer"
                        icon="InfoIcon"
                        size="20"
                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                        v-b-tooltip.hover.v-dark
                        title="Ce champ vous permet de définir le montant minimum
                    autoriser à utiliser le code promo"
                        variant="gradient-primary"
                      />
                    </h5>
                  </div>
                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="text"
                      name="price"
                      placeholder="Dépense minimale"
                      v-model="codeDepenseMinimal"
                      @keypress="stripTheGarbage($event)"
                      @blur="formatDinar()"
                      required
                    />
                    <span class="currency-symbol">TND </span>
                  </div>
                </div>
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <h5 class="form-label">
                      Dépense maximal
                      <feather-icon
                        style="color: #19b4bf; cursor: pointer"
                        icon="InfoIcon"
                        size="20"
                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                        v-b-tooltip.hover.v-dark
                        title="Ce champ vous permet de définir le montant maximal
                    autoriser à utiliser le code promo"
                        variant="gradient-primary"
                      />
                    </h5>
                  </div>
                  <div class="currency-input">
                    <input
                      class="form-control"
                      type="text"
                      name="price"
                      v-model="codeDepenseMax"
                      placeholder="Dépense maximal"
                      @keypress="stripTheGarbage($event)"
                      @blur="formatDinar()"
                      required
                    />
                    <span class="currency-symbol">TND </span>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <h5>Utilisation individuelle uniquement</h5>
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="codeUseIndividuel"
                  name="checkbox-2"
                >
                  Cochez cette case si le code promo ne peut être utilisé
                  conjointement avec d’autres codes promo.
                </b-form-checkbox>
              </div>
              <div class="mb-3">
                <h5>Exclure les articles en promo</h5>
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="codeArticlePromo"
                  name="checkbox-3"
                >
                  Cochez cette case si le code promo ne doit pas être appliqué
                  aux articles en promo.
                </b-form-checkbox>
              </div>
              <div class="mb-2">
                <h3>Limites d'utilisation</h3>
              </div>

              <div class="mb-3">
                <h5 class="form-label">
                  Limite d’utilisation par code
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir combien de fois ce code promo peut il ètre utilisé avant de
                    ne plus etre valide"
                    variant="gradient-primary"
                  />
                </h5>

                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="codeLimitUse"
                  placeholder="Limite utilisation"
                />
              </div>
              <div class="mb-3">
                <h5 class="form-label">
                  Limite d’utilisation par utilisateur
                  <feather-icon
                    style="color: #19b4bf; cursor: pointer"
                    icon="InfoIcon"
                    size="20"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-tooltip.hover.v-dark
                    title="Ce champ vous permet de définir Combien de fois ce code promo peut il ètre utilisé par un
                    utilisateur"
                    variant="gradient-primary"
                  />
                </h5>

                <input
                  class="form-control"
                  type="number"
                  min="0"
                  v-model="codeLimitUserUse"
                  placeholder="Limite utilisation par utilisateurs"
                />
              </div>
              <div class="d-flex flex-row-reverse">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click="codePromoAddShowMsgBox()"
                  :disabled="codeTitre.length == 0"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-title mb-0">Liste codes promo</h3>
            </div>
            <div>
              <b-table
                :items="listCodePromo.codePromo"
                :fields="fields"
                :busy="isBusy"
                striped
                hover
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left: 5px">Loading...</strong>
                  </div>
                </template>
                <template #cell(discount_type)="row">
                  <label v-if="row.item.discount_type == 'fixed_cart'"
                    >Remise panier fixe</label
                  >
                  <label v-if="row.item.discount_type == 'percent'"
                    >Remise en pourcentage</label
                  >
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      class="mx-1"
                      @click="RedirectToEditCodePromo(data.item.id)"
                    />
                    <b-tooltip
                      title="Modification"
                      :target="`invoice-row-${data.item.id}-edit-icon`"
                    />

                    <feather-icon
                      :id="`invoice-row-${data.item.id}-trash-icon`"
                      icon="TrashIcon"
                      @click="confirmationDeleteCodePromo(data.item.id)"
                    />
                    <b-tooltip
                      title="Supprimer"
                      :target="`invoice-row-${data.item.id}-trash-icon`"
                    />
                  </div>
                </template>
              </b-table>
              <b-pagination
                class="mt-3"
                align="center"
                v-model="currentPage"
                :total-rows="listCodePromo.totalCodePromo"
                :per-page="perPage"
                @change="actualisationCodePromo"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import vSelect from "vue-select";
import {
  BFormDatepicker,
  BFormCheckbox,
  BButton,
  BOverlay,
  VBTooltip,
  BPagination,
  BTable,
  BFormInput,
  BTooltip,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import websiteURL from "@/websiteURL";
import Ripple from "vue-ripple-directive";
import {
  getAllCodePromo,
  deleteCodePromo,
} from "../../views/CodesPromo/CodePromoFunction";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BOverlay,
    VBTooltip,
    BTable,
    BFormInput,
    BTooltip,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      typeCodePromo: [
        { value: "percent", text: "Remise en pourcentage" },
        { value: "fixed_cart", text: "Remise panier fixe" },
      ],
      status: false,
      codeTitre: "",
      codeType: [],
      codePrice: "",
      codeExpiration: "",
      codeDepenseMinimal: "",
      codeDepenseMax: "",
      codeUseIndividuel: false,
      codeArticlePromo: false,
      codeLimitUse: "",
      codeLimitUserUse: "",
      boxMsgAddCodePromo: "",
      loading: false,
      fields: [
        {
          label: "Code",
          key: "code",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Type",
          key: "discount_type",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Valeur",
          key: "amount",
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      test: false,
      listCodePromo: [],
      perPage: 5,
      currentPage: 1,
      boxMsg: "",
    };
  },

  methods: {
    async RedirectToEditCodePromo(item) {
      this.$router.push({
        name: "Edit Code Promo",
        params: { id: item },
      });
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    formatDinar() {
      if (this.codePrice != "") {
        var num = this.codePrice;

        num = Number(num);

        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };

        var decimal = countDecimals(num);

        if (decimal < 2) {
          num = num.toFixed(2);
        }

        if (decimal > 2) {
          num = num.toFixed(decimal);
        }

        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }

        this.codePrice = num;
      }
    },
    //Permet de generer un code aléatoirement
    generateCode() {
      this.codeTitre = Math.random().toString(36).toUpperCase().slice(2, 10);
    },

    //Permet de réinitialiser les champs aprés l'ajout d'un code promo
    clearData() {
      this.status = false;
      this.codeTitre = "";
      this.codeType = "";
      this.codePrice = "";
      this.codeExpiration = "";
      this.codeDepenseMinimal = "";
      this.codeDepenseMax = "";
      this.codeUseIndividuel = false;
      this.codeArticlePromo = false;
      this.codeLimitUse = "";
      this.codeLimitUserUse = "";
    },
    //Permet l'ajout d'un nouveau code promo
    async addCodePromo() {
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/coupons";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          code: this.codeTitre,
          discount_type: this.codeType.value,
          individual_use: this.codeUseIndividuel,
          amount: this.codePrice,
          exclude_sale_items: this.codeArticlePromo,
          minimum_amount: this.codeDepenseMinimal,
          maximum_amount: this.codeDepenseMax,
          usage_limit: Number(this.codeLimitUse),
          usage_limit_per_user: Number(this.codeLimitUserUse),
          date_expires: this.codeExpiration,
          free_shipping: this.status,
        };

        const response = await axios.post(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.codePromoAddToastMsg("info");
        this.clearData();
      } catch (error) {
        alert(
          "Erreur lors de l'ajout du code promo, veuillez réessayer plus tard ",
          error
        );
      }
    },
    codePromoAddShowMsgBox() {
      this.boxMsgAddCodePromo = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir ajouter le code promo ?", {
          title: "Confirmation",
          size: "md",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgAddEtiquette = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.addCodePromo().then(async () => {
                this.loading = false;
                await this.actualisationCodePromo();
              });
            }
          }
        });
    },
    codePromoAddToastMsg(variant = null) {
      this.$bvToast.toast("Le code promo a bien été ajouter", {
        title: `Code promo ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet l'actualisation du tableau
    async actualisationCodePromo(event) {
      this.isBusy = true;
      if (event === null) {
        this.listCodePromo = await getAllCodePromo(
          this.perPage,
          this.currentPage
        );
      } else {
        this.listCodePromo = await getAllCodePromo(this.perPage, event);
      }
      this.isBusy = false;
    },
    //Boite dialogue spour confirmer ou non la suppresion d'un code promo
    confirmationDeleteCodePromo(idCodePromo) {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer le code promo ?", {
          title: "Confirmation",
          size: "md",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              await deleteCodePromo(idCodePromo).then(async () => {
                this.loading = false;
                this.codePromoDeleted("info");
                this.isBusy = true;
                await this.actualisationCodePromo();
                this.isBusy = false;
              });
            }
          }
        });
    },
    //Message toast indiquand que la suppression est réussi
    codePromoDeleted(variant = null) {
      this.$bvToast.toast("Le code promo a bien été supprimer", {
        title: `Code promo ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
  async created() {
    await this.actualisationCodePromo();
  },
};
</script>
<style lang="scss">
// Style
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
